.edica-landing-section {
    padding-top: 75px;
    padding-bottom: 60px;

    .edica-landing-section-subtitle {
        font-size: 22px;
        line-height: 2.14;
        margin-bottom: 16px;
        font-weight: $font-weight-normal;
    }

    .edica-landing-section-subtitle-alt {
        font-size: 18px;
        font-weight: $font-weight-medium;
        line-height: 1;
        padding-left: 111px;
        position: relative;
        color: #bbc5d2;
        margin-bottom: 33px;

        &::before {
            content: "";
            display: inline-block;
            width: 90px;
            height: 1px;
            background-color: #bbc5d2;
            position: absolute;
            left: 0;
            top: 50%;
        }
    }

    p {
        font-size: 18px;
        line-height: 1.56;
        color: #3a3a3a;
    }

    .edica-landing-section-title {
        font-size: 45px;
        font-weight: $font-weight-bold;
        line-height: 1.04;
        margin-bottom: 48px;
    }

    &.edica-landing-about {
        padding-top: 147px;

        p {
            margin-bottom: 33px;
        }

        .landing-about-list {
            list-style: none;

            li {
                position: relative;
                font-size: 18px;
                margin-bottom: 21px;
                font-weight: $font-weight-medium;
                color: $black;

                &::before {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    border-width: 6px;
                    border-style: solid;
                    border-color: transparent #f29431 #f29431 transparent;
                    left: -40px;
                    top: 6px;
                }
            }
        }
    }

    &.edica-landing-clients {
        .partners-wrapper {
            
            img {
                margin: 28px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &.edica-landing-services {
        .landing-service-card {
            margin-bottom: 85px;

            .service-card-img {
                margin-bottom: 36px;
            }
            .service-card-title {
                font-size: 25px;
                font-weight: $font-weight-bold;
                margin-bottom: 15px;
            }
            .service-card-description {
                font-size: 18px;
                line-height: 1.56;
            }
        }
    }

    &.edica-landing-testimonials {
        background-color: #fff3ee;

        .landing-testimonial-carousel {
            blockquote {
                padding-top: 40px;
                padding-bottom: 40px;

                p {
                    font-size: 35px;
                    font-weight: $font-weight-bold;
                    line-height: 1.29;
                    text-align: center;
                    color: $black;

                    @media (max-width: 575px) {
                        font-size: 26px;
                    }
                }
            }
            .carousel-indicators {
                position: static;
                display: flex;
                justify-content: space-between;

                @media (max-width: 575px) {
                    margin-left: 0;
                    margin-right: 0;
                    align-items: center;
                }

                li {
                    width: auto;
                    height: auto;
                    text-indent: 0;
                    opacity: 1;
                    background-color: transparent;
                    text-align: center;
                    padding-top: 8px;

                    @media (max-width: 575px) {
                        padding-top: 0;
                    }

                    img {
                        border-radius: 50%;
                        width: 65px;
                        height: 65px;
                        transition: all 0.5s ease;
                        margin-top: 8px;
                        margin-bottom: 9px;

                        @media (max-width: 575px) {
                            width: 35px;
                            height: 35px;
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }

                    .user-details {
                        visibility: hidden;
                        text-align: center;

                         h6 {
                             margin-bottom: 0;

                            @media (max-width: 575px) {
                                display: none;
                            }
                         }

                         p{
                             font-size: 13px;
                             color: $black;
                             margin-bottom: 0;

                            @media (max-width: 575px) {
                                display: none;
                            }
                         }

                    }

                    &.active {
                        padding-top: 0;
                        img {
                            height: 82px;
                            width: 82px;

                            @media (max-width: 575px) {
                                width: 40px;
                                height: 40px;
                            }
                        }

                        .user-details {
                            visibility: visible;
                        }
                    }
                }
                
            }
        }
    }

    &.edica-landing-blog {
        .edica-landing-section-title {
            font-size: 35px;
            line-height: 1.29;
            margin-bottom: 66px;
        }

        .landing-blog-post {
            .blog-post-thumbnail {
                width: 100%;
                margin-bottom: 37px;
            }

            .blog-post-category {
                font-size: 16px;
                line-height: 1.13;
                color: #7d848b;
                margin-bottom: 22px;
            }
            
            .blog-post-title {
                font-size: 22px;
                font-weight: $font-weight-medium;
                color: $black;
            }

            .blog-post-link {
                color: theme-color(warning);

                &::after {
                    content: "\2192";
                    display: inline-block;
                    color: theme-color(warning);
                    font-size: 16px;
                    margin-left: 14px;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    text-decoration: none;

                    &::after {
                        transform: translateX(5px);
                    }
                }
            }
        }
    }

    &.edica-landing-blog-posts {
        .blog-post-card {
            padding: 45px 60px;
            border-radius: 8px;

            .post-category {
                font-size: 18px;
                font-weight: $font-weight-medium;
                line-height: 0.89;
                margin-bottom: 8px;
            }

            .post-title {
                font-size: 30px;
                font-weight: $font-weight-bold;
            }

            .post-link {
                font-size: 18px;
                font-weight: $font-weight-medium;

                &::after {
                    content: "\2192";
                    display: inline-block;
                    color: inherit;
                    font-size: 16px;
                    margin-left: 8px;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    text-decoration: none;

                    &::after {
                        transform: translateX(5px);
                    }
                }
            }

            &.blog-post-1 {
                background-color: #fcf9ec;

                .post-category {
                    color: theme-color(warning);
                }

                .post-link {
                    color: theme-color(warning);
                }
            }

            &.blog-post-2 {
                background-color: #f4fbf8;

                .post-category {
                    color: theme-color(success);
                }

                .post-link {
                    color: theme-color(success)
                }
            }
        }
    }
}